<div class="content">
  <h3 class="section-title">Business Hours*</h3>
  <app-toggle-item
    itemName="Always Open"
    specialContent="
    <p class='tw-font-inter tw-text-[10px] tw-text-enstack-onyx-80'>
      <span>Switch toggle to </span>
      <span class='tw-text-[#0099E0]'>ON to accept orders at any time, 24/7</span><span>, or OFF to decline orders when closed.</span>
    </p>"
    (toggleChanged)="toggleAlwaysOpen($event)"
    [isChecked]="isAlwaysOpen"
  />

  <div *ngFor="let businessHours of businessHoursArray; let i = index">
    <app-toggle-item
      [itemName]="businessHours.day"
      (toggleChanged)="setAvailability(i, businessHours.key, $event)"
      [isChecked]="businessHours.isAvailable"
    />
    <div class="divider"></div>

    <app-time-picker
      [day]="businessHours.day"
      [startTime]="businessHours.startTime"
      [endTime]="businessHours.endTime"
      [disabled]="!businessHours.isAvailable"
      (timeChanged)="handleChangeTime(i, businessHours.key, $event)"
    />
  </div>

  <app-cta-buttons
    currentPage="email-verification"
    [pageNames]="starterPages"
    tier="starter"
    [disabled]="false"
    [data]="formData"
    (nextEvent)="handleNextClick()"
    [nextCallback]="handleNextClick"
    [isLoading]="loading"
  ></app-cta-buttons>
</div>
