import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private cookieService: CookieService) { }

  setToken(token:string):void{
    this.cookieService.put('token', token)
  }

  getToken():string{
    return this.cookieService.get('token') ?? ''
  }

  isAuthenticated(): boolean{
    return !!this.cookieService.get('token')
  }

  getuserid(){
    const authdata = localStorage.getItem("authuser")
    const authdatajson  = JSON.parse(authdata!)
    return authdatajson["id"]
  }

  clearToken(): void{
    this.cookieService.remove('token')
  }

  getemail() {
    const authdata = localStorage.getItem("authuser")
    const authdatajson  = JSON.parse(authdata!)
    return authdatajson["email"]
  }
}
