import { Component, OnInit, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TimePickerComponent } from 'src/app/components/time-picker/time-picker.component';
import { ToggleItemComponent } from 'src/app/components/toggle-item/toggle-item.component';
import { distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';
import { starterPages } from '../starter-data';
import { CtaButtonsComponent } from 'src/app/components/cta-buttons/cta-buttons.component';
import { StarterFormDataService } from '../starter-form-data.service';
type BusinessHours = {
  key: string;
  day: string;
  startTime: FormControl;
  endTime: FormControl;
  isAvailable: boolean;
  dayprio?: number;
}[];

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss'],
  standalone: true,
  imports: [
    ToggleItemComponent,
    TimePickerComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CtaButtonsComponent,
  ],
})
export class BusinessHoursComponent implements OnInit {
  @Input({ required: true }) form!: FormGroup;
  @Input() isSaveHidden: FormControl = new FormControl(true);
  initialBusinessHoursValue: any;
  isAlwaysOpen: boolean = false;
  starterPages = starterPages;
  formData: FormData[] = [];
  newBusinessHoursArray: any[] = [];
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private starterService: StarterFormDataService,
  ) { }

  startAndEndTimes = this.fb.group({
    mondaysStartTime: new FormControl('9:00 AM'),
    mondaysEndTime: new FormControl('6:00 PM'),
    tuesdaysStartTime: new FormControl('9:00 AM'),
    tuesdaysEndTime: new FormControl('6:00 PM'),
    wednesdaysStartTime: new FormControl('9:00 AM'),
    wednesdaysEndTime: new FormControl('6:00 PM'),
    thursdaysStartTime: new FormControl('9:00 AM'),
    thursdaysEndTime: new FormControl('6:00 PM'),
    fridaysStartTime: new FormControl('9:00 AM'),
    fridaysEndTime: new FormControl('6:00 PM'),
    saturdaysStartTime: new FormControl('9:00 AM'),
    saturdaysEndTime: new FormControl('6:00 PM'),
    sundaysStartTime: new FormControl('9:00 AM'),
    sundaysEndTime: new FormControl('6:00 PM'),
  });


  daypriorities: { [key: string]: number } = {
    "MONDAY": 0,
    "TUESDAY": 1,
    "WEDNESDAY": 2,
    "THURSDAY": 3,
    "FRIDAY": 4,
    "SATURDAY": 5,
    "SUNDAY": 6
  }

  businessHoursArray: BusinessHours = [
    {
      key: 'MONDAY',
      day: 'Mondays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.mondaysStartTime,
      endTime: this.startAndEndTimes.controls.mondaysEndTime,
    },
    {
      key: 'TUESDAY',
      day: 'Tuesdays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.tuesdaysStartTime,
      endTime: this.startAndEndTimes.controls.tuesdaysEndTime,
    },
    {
      key: 'WEDNESDAY',
      day: 'Wednesdays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.wednesdaysStartTime,
      endTime: this.startAndEndTimes.controls.wednesdaysEndTime,
    },
    {
      key: 'THURSDAY',
      day: 'Thursdays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.thursdaysStartTime,
      endTime: this.startAndEndTimes.controls.thursdaysEndTime,
    },
    {
      key: 'FRIDAY',
      day: 'Fridays',
      isAvailable: true,
      startTime: this.startAndEndTimes.controls.fridaysStartTime,
      endTime: this.startAndEndTimes.controls.fridaysEndTime,
    },
    {
      key: 'SATURDAY',
      day: 'Saturdays',
      isAvailable: false,
      startTime: this.startAndEndTimes.controls.saturdaysStartTime,
      endTime: this.startAndEndTimes.controls.saturdaysEndTime,
    },
    {
      key: 'SUNDAY',
      day: 'Sundays',
      isAvailable: false,
      startTime: this.startAndEndTimes.controls.sundaysStartTime,
      endTime: this.startAndEndTimes.controls.sundaysEndTime,
    },
  ];

  ngOnInit() {
    this.starterService.setCheckPointIndex(3)
    this.startAndEndTimes.valueChanges.subscribe(() => {
      this.saveTime();
    });

    if (this.form) {
      this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        // this.initializeBusinessHours();
      });
    }
    if (typeof this.starterService.shopBusinessHours === 'undefined') {
      this.starterService.getShopBusinessHoursV2().subscribe((data: any) => {
        this.starterService.shopBusinessHours = data
        this.setBusinessHours()
      })
    } else {
      this.setBusinessHours()
    }

    if (typeof this.starterService.merchantData === 'undefined') {
      this.starterService.getMerchantDetailsV2().subscribe((data: any) => {
        this.starterService.merchantData = data
      })
    }
  }

  setBusinessHours() {
    // set default time if new user
    // check if business hours time is 00:00
    var businessHours = this.starterService.shopBusinessHours.business_hours
    var isNew = businessHours.every(
      (day: any) => day.schedule_hour_from === '00:00:00' && day.schedule_hour_to === '00:00:00'
    );

    console.log('isUserNew', isNew);

    // is whole day
    this.isAlwaysOpen = Boolean(this.starterService.shopBusinessHours.take_order_status.is_whole_day);
    if (!isNew) {

      // set day hours
      const businessWeekHours = this.starterService.shopBusinessHours.business_hours;
      this.businessHoursArray = []
      businessWeekHours.forEach((dayObj: any) => {
        this.businessHoursArray.push({
          key: dayObj.day,
          day: dayObj.day,
          dayprio: (this.daypriorities[(dayObj.day) as string]) as number,
          isAvailable: dayObj.is_active,
          startTime: new FormControl(this.convertTo24HourFormat(dayObj.schedule_hour_from)),
          endTime: new FormControl(this.convertTo24HourFormat(dayObj.schedule_hour_to)),
        })
      })

      console.log("sorted", this.businessHoursArray)
    }
    // this.setFormValues(formValue, this.startAndEndTimes)
    (this.businessHoursArray as any[]).sort(function (a: any, b: any) {
      let daypriorities: { [key: string]: number } = {
        "MONDAY": 0,
        "TUESDAY": 1,
        "WEDNESDAY": 2,
        "THURSDAY": 3,
        "FRIDAY": 4,
        "SATURDAY": 5,
        "SUNDAY": 6
      }
      let keyA = daypriorities[a.key]
      let keyB = daypriorities[b.key]
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    })
    console.log("sorted2", this.businessHoursArray)

  }

  initializeBusinessHours() {
    if (this.form) {
      const formValue = this.form.value.business_hours?.business_hours;
      if (formValue) {
        this.businessHoursArray.forEach((businessDay) => {
          const updatedItem = formValue.find(
            (item: any) => item?.day === businessDay.key
          );
          if (updatedItem) {
            businessDay.isAvailable = updatedItem.is_active;
          }
        });
        this.setFormValues(formValue, this.startAndEndTimes);
        this.initialBusinessHoursValue = formValue.map((item: any) => ({
          ...item,
        }));
      }
    }
  }

  setFormValues(scheduleArray: any[], form: FormGroup) {
    scheduleArray.forEach((schedule) => {
      const day = schedule.day.toLowerCase();
      form.controls[`${day}sStartTime`].setValue(
        this.convertTo12HourFormat(schedule.schedule_hour_from)
      );
      form.controls[`${day}sEndTime`].setValue(
        this.convertTo12HourFormat(schedule.schedule_hour_to)
      );
    });
    this.isSaveHidden.setValue(true);
  }

  saveTime() {
    if (this.form) {
      const formValue = this.form.value.business_hours?.business_hours;
      const currentArray: BusinessHours = this.businessHoursArray;

      if (formValue) {
        formValue.forEach((businessDay: any) => {
          const updatedItem = currentArray.find(
            (item) => item?.key === businessDay.day
          );
          if (updatedItem) {
            businessDay.schedule_hour_from = this.convertTo24HourFormat(
              updatedItem.startTime.value
            );
            businessDay.schedule_hour_to = this.convertTo24HourFormat(
              updatedItem.endTime.value
            );
            businessDay.is_active = updatedItem.isAvailable;
          }
        });
      }
      const hasChanges = isEqual(formValue, this.initialBusinessHoursValue);
      this.isSaveHidden.setValue(hasChanges);
    }
  }

  setAvailability(dayIndex: number, dayKey: string, status: boolean) {
    this.businessHoursArray[dayIndex].isAvailable = status;
    if (this.form) {
      const formValue = this.form.value.business_hours?.business_hours;

      if (formValue) {
        const businessDayToUpdate = formValue.find(
          (item: any) => item?.day === dayKey
        );
        if (businessDayToUpdate) {
          businessDayToUpdate.is_active = status;
        }
      }

      const hasChanges = isEqual(formValue, this.initialBusinessHoursValue);
      this.isSaveHidden.setValue(hasChanges);
    }
  }

  toggleAlwaysOpen(status: boolean): void {
    // if (this.form) {
    //   this.form.get('business_hours.is_whole_day')?.setValue(status);
    // }
    this.isAlwaysOpen = status;
  }

  convertTo24HourFormat(time: string): string {
    const [timePart, period] = time.split(' ');
    const [hours, minutes] = timePart.split(':').map(Number);
    let convertedHours = hours;

    if (period === 'PM' && hours < 12) {
      convertedHours += 12;
    } else if (period === 'AM' && hours === 12) {
      convertedHours = 0;
    }

    return `${convertedHours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  convertTo12HourFormat(time: string): string {
    let [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${period}`;
  }

  handleNextClick = async () => {
    this.loading = true
    return await this.saveBusinessHours().then(() => {
      this.loading = false
      return true
    }).catch(() => {
      this.loading = false
      return false
    })
  }

  async saveBusinessHours() {
    this.businessHoursArray.forEach((businessDay) => {
      this.newBusinessHoursArray.push({
        day: businessDay.key,
        is_active: businessDay.isAvailable,
        schedule_hour_from: this.convertTo24HourFormat(businessDay.startTime.value),
        schedule_hour_to: this.convertTo24HourFormat(businessDay.endTime.value),
      })
    });

    var store = {
      'store': {
        "shop_name": this.starterService.merchantData.shop_name,
        "is_whole_day": this.isAlwaysOpen,
      },
      "business_hours": this.newBusinessHoursArray
    }

    console.log('toSave:', store);
    this.starterService.updateBusinessHours(store)

    // set vacation to true if everything is false
    var isVacation = false
    if ((this.businessHoursArray.every(day => !day.isAvailable)) && !this.isAlwaysOpen) {
      isVacation = true;
    }

    return await this.starterService.updateVacationMode(
      this.starterService.merchantData.store_id
      , isVacation
    ).then(() => {
      return true
    }).catch(() => {
      return false
    })
  }

  onStartTimeChange(newStartTime: Event): void {
    console.log('valueChanged: New start time:', newStartTime);
  }

  onEndTimeChange(newEndTime: Event): void {
    console.log('valueChanged: New end time:', newEndTime);
  }

  handleChangeTime(index: number, key: string, event: any) {
    console.log('valueChanged:', index, key, event);
  }
}
