<div class="container">
  <div class="page-info">
    <h3 class="title">Upload a Selfie with your ID<span class="tw-text-[#9FE9FE]">*</span></h3>
    <span class="note">Make sure you are holding the ID you've submitted.</span>
  </div>

  <!-- <app-document-upload
    sampleImageUrl="../../../../assets/images/sample-ids/selfie-sample-pic.png"
    [withIdDetails]="false"
    (documentChange)="onDocumentChange($event)"
    [form]="form"
    id="selfie"
  /> -->

  <app-document-upload
    sampleImageUrl="../../../../assets/images/sample-image-id.png"
    (documentChange)="onDocumentChange('selfie', $event)"
    [form]="form"
    [capturecam]="'user'"
    [id]="'selfie'"
  />
  
  <div class="end-note">
    @if (uploadError) {
      <div>
        <p class="error-message">{{ uploadError }}</p>
      </div>
    }
    <p>
      Upload a clear picture of your selfie holding your primary ID or one of your secondary IDs submitted earlier. Make sure that your face and ID are clearly visible and without obstruction. Please remove any accessories like glasses, sunglasses, and face masks.
    </p>
  </div>
</div>

<app-cta-buttons
  currentPage="poi"
  [nextCallback]="onNextPressed"
  [pageNames]="advancedPages"
  [disabled]="!this.form.valid"
  tier="advanced"
  (nextEvent)="onNextPressed()"
  [isLoading]="loading"
></app-cta-buttons>
