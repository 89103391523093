import { CommonModule, NgIf } from '@angular/common';
import {
  Component,
  Input,
  Provider,
  computed,
  forwardRef,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { FileUrlPipe } from './pipes/file-url.pipe';
import { ValidationMessageFn } from '../types/validation-message-fn';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../image-dialog/image-dialog.component';

const REACTIVE_FILE_INPUT_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReactiveFileInputComponent),
  multi: true,
};

@Component({
  selector: 'app-reactive-file-input',
  templateUrl: './reactive-file-input.component.html',
  styleUrls: ['./reactive-file-input.component.scss'],
  providers: [REACTIVE_FILE_INPUT_ACCESSOR],
  standalone: true,
  imports: [CommonModule, FileUrlPipe],
})
export class ReactiveFileInputComponent implements ControlValueAccessor {
  @Input()
  public labelText: string = 'File Input';

  @Input()
  public placeholder: string = 'Upload or Take a Photo';

  @Input()
  public id: string = 'file-input';

  @Input()
  public sampleImageUrl: string = '';

  // Should we accept PDFs here, update the validateLargeFile functions in ID and selfier upload
  // Image compressor will not work with pdf files
  @Input()
  public acceptedFileTypes: Array<String> = [
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];

  @Input()
  public errors: ValidationErrors | null | undefined = null;

  @Input()
  public isTouched = false;

  @Input()
  public capturecam: string | null = null;

  @Input()
  public capture: string = '';

  @Input()
  public validationMessage: Record<string, ValidationMessageFn> = {};

  public file = signal<File | null>(null);
  public hasFile = computed(() => this.file() !== null);
  public isFileImage = computed(() => {
    const file = this.file();
    return file ? file.type.includes('image') : false;
  });

  constructor(private dialog: MatDialog) {}

  public get hasErrors(): boolean {
    return this.errors !== null;
  }

  public buildAcceptString(): string {
    return this.acceptedFileTypes.join(',');
  }

  public onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0] || null;

    this.file.set(file);
    this.onChange(file);
    this.onTouched();
  }

  public onFileRemoved(): void {
    this.file.set(null);
    this.onChange(null);
    this.onTouched();
  }

  public get errorMessage(): string {
    const firstError = Object.keys(this.errors || {})[0];
    const messageFn = this.validationMessage[firstError];
    return messageFn ? messageFn(this.errors) : '';
  }

  private onChange: (value: File | null) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(obj: File): void {
    this.file.set(obj);
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  openImageDialog(imageUrl: string): void {
    this.dialog.open(ImageDialogComponent, {
      data: { imageUrl },
      maxWidth: '80vw',
      maxHeight: '80vh',
    });
  }

  openFileDialog(): void {
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    fileInput.click();
  }

  openCameraDialog(): void {
    const cameraInput = document.getElementById(
      'camera-input'
    ) as HTMLInputElement;
    cameraInput.click();
  }

  openChoiceDialog(): void {
    // Open the choice dialog to let the user choose between upload and camera
    const choiceDialog = document.getElementById(
      'choice-dialog'
    ) as HTMLDialogElement;
    choiceDialog.showModal();
  }

  closeChoiceDialog(): void {
    const choiceDialog = document.getElementById(
      'choice-dialog'
    ) as HTMLDialogElement;
    choiceDialog.close();
  }
}
