<div class="container">
  <div class="id-detail" *ngIf="withIdDetails && id != 'selfie'">
    <app-reactive-dropdown-input
      placeholder="{{id[0] === 's' ? 'ID Type #' + id[id.length - 1] : 'ID Type'}}"
      [control]="form.controls.idType"
      [errors]="form.controls.idType.errors"
      [errorMessage]="errorMessage(documentUploadFormKeys.ID_TYPE)"
    >
      <ng-template #optionsSlot>
        @for (idType of filteredIdTypes; track $index) {
        <option [value]="idType.name">{{ idType.name }}</option>
        }
      </ng-template>
    </app-reactive-dropdown-input>
  </div>

  <div class="id-detail" *ngIf="withIdDetails && hasExpiry && id != 'selfie'">
    <!-- <h3 style="padding-bottom: 7.5px">Expiration</h3> -->
    <app-reactive-date-picker
      [errors]="form.controls.expiration.errors"
      [validationMessage]="validationMessages"
      [formControl]="form.controls.expiration"
      [isTouched]="form.controls.expiration.touched"
      placeholder="Expiration"
    />
  </div>

  <app-reactive-file-input
    [id]="id"
    [labelText]="labelText"
    [capturecam]="capturecam"
    [sampleImageUrl]="sampleImages[0]"
    [acceptedFileTypes]="['image/*']"
    [capture]="'environment'"
    [formControl]="form.controls.file"
    [errors]="form.controls.file.errors"
    [validationMessage]="validationMessages"
    [isTouched]="form.controls.file.touched"
  />

  <app-reactive-file-input
    *ngIf="hasBackOfId"
    [id]="id + '-back'"
    [labelText]="labelText2"
    [capturecam]="capturecam"
    [sampleImageUrl]="sampleImages[1]"
    [acceptedFileTypes]="['image/*']"
    [capture]="'environment'"
    [formControl]="secondaryForm.controls.file || form.controls.file"
    [errors]="form.controls.file.errors"
    [validationMessage]="validationMessages"
    [isTouched]="form.controls.file.touched"
  />
</div>
