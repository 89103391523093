import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatTimepickerModule,
    CommonModule,
  ],
})
export class TimePickerComponent implements OnInit {
  @Input() startTime = new FormControl('9:00 AM');
  @Input() endTime = new FormControl('6:00 PM');
  @Input() disabled: boolean = true;
  @Input() day: string = '';

  constructor() {}

  ngOnInit() {}
}
