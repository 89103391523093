<!-- <p>poi works!</p> -->

<div class="container">
  <div class="section">
    <div class="section-info">
      <h3 class="title">Proof of Identity<span class="tw-text-[#9FE9FE]">*</span></h3>
      <span class="note">Select which proof of identity to upload</span>
    </div>

    <div class="poi-options">
      <div class="poi-option" (click)="changeIdCategory('primary')">
        <div class="option-details">
          <h3 class="title">One Primary ID</h3>
          <p class="note">
            Passport, Driver’s License, SSS ID, UMID Card, PRC, Voter’s ID,
            National ID, and Student’s ID (Current year’s ID -Enrolled)
          </p>
        </div>
        <img src="../../../../assets/icons/arrow-right.svg" alt="Arrow Right" />
      </div>
      <div class="separator">
        <div class="bar"></div>
        <div>
          <span>OR</span>
        </div>
        <div class="bar"></div>
      </div>
      <div class="poi-option" (click)="changeIdCategory('secondary')">
        <div class="option-details">
          <h3 class="title">Two Secondary IDs</h3>
          <p class="note">
            NBI Clearance, PhilHealth, PSA Birth Certificate, and Postal ID.
          </p>
        </div>
        <img src="../../../../assets/icons/arrow-right.svg" alt="Arrow Right" />
      </div>
    </div>
  </div>

  <app-cta-buttons
    currentPage="poi"
    [pageNames]="advancedPages"
    tier="advanced"
    [nextButton]="false"
  ></app-cta-buttons>
</div>
