<!-- <div class="time-picker-container">
  <div class="start-time time">
    <span>9:00 AM</span>
  </div>

  <img src="../../../../assets/icon/to-icon.svg" alt="To" />

  <div class="end-time time">
    <span>9:00 AM</span>
  </div>
</div> -->

<div class="time-picker-container">
  <div class="start-time time" [class.disabled]="disabled">
    <input
      matInput
      [ngxMatTimepicker]="startTimePicker"
      placeholder="Start Time"
      [formControl]="startTime"
      [disabled]="disabled"
    />
    <!-- <ngx-mat-timepicker-toggle
      matSuffix
      [for]="startTimePicker"
    ></ngx-mat-timepicker-toggle> -->
    <ngx-mat-timepicker #startTimePicker></ngx-mat-timepicker>
  </div>

  <img *ngIf="!disabled" src="../../../assets/icons/to-icon.svg" alt="To" />
  <img
    *ngIf="disabled"
    src="../../../assets/icons/to-icon-disabled.svg"
    alt="To"
  />

  <div class="end-time time" [class.disabled]="disabled">
    <input
      matInput
      [ngxMatTimepicker]="endTimePicker"
      placeholder="End Time"
      [formControl]="endTime"
      [disabled]="disabled"
    />
    <!-- <ngx-mat-timepicker-toggle
      matSuffix
      [for]="endTimePicker"
    ></ngx-mat-timepicker-toggle> -->
    <ngx-mat-timepicker #endTimePicker></ngx-mat-timepicker>
  </div>
</div>
