import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { Injectable } from '@angular/core';
import { generate } from 'rxjs';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  constructor(private authService: AuthService) {}

  configureDatadog() {
    datadogLogs.init({
      clientToken: environment.DDCLIENTTOKEN,
      site: 'datadoghq.com',
      service: 'onboarding-' + environment.STAGE,
      env: environment.STAGE,
      forwardConsoleLogs: 'all',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });

    datadogRum.init({
      applicationId: environment.DDAPPLICATIONID,
      clientToken: environment.DDCLIENTTOKEN,
      site: 'datadoghq.com',
      service: 'onboarding-' + environment.STAGE,
      env: environment.STAGE,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    try {
      const user_id = this.authService.getuserid();
      const email = this.authService.getemail();

      datadogRum.setUser({
        id: user_id,
        email: email,
      });
    } catch (error) {
      console.error('[DATADOG] user not found. Not setting user in datadog');
    }

    datadogRum.startSessionReplayRecording();
  }
}
