export interface IdType {
  id: number;
  name: string;
  category: string;
  sample: string[];
  hasBackOfId: boolean;
  hasExpiry: boolean;
}

export const advancedPages: string[] = [
  'poi',
  'upload-id',
  'upload-selfie',
  'business-info',
];

export const idTypes: IdType[] = [
  {
    id: 1,
    name: 'Comelec Voter’s ID',
    category: 'primary',
    sample: ['../../../assets/images/sample-ids/comelec-id.png'],
    hasBackOfId: false,
    hasExpiry: false,
  },
  {
    id: 2,
    name: 'Driver’s License',
    category: 'primary',
    sample: ['../../../assets/images/sample-ids/drivers-license-front.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 3,
    name: 'Philippine Identification Card (National ID)',
    category: 'primary',
    sample: [
      '../../../assets/images/sample-ids/nat-id-front.png',
      '../../../assets/images/sample-ids/nat-id-back.png',
    ],
    hasBackOfId: true,
    hasExpiry: false,
  },
  {
    id: 4,
    name: 'Philippine Identification ePhilID',
    category: 'primary',
    sample: [
      '../../../assets/images/sample-ids/phylsis-front.png',
      '../../../assets/images/sample-ids/phylsis-back.png',
    ],
    hasBackOfId: true,
    hasExpiry: false,
  },
  {
    id: 5,
    name: 'Passport',
    category: 'primary',
    sample: [
      '../../../assets/images/sample-ids/passport-front.png',
      '../../../assets/images/sample-ids/passport-back.png',
    ],
    hasBackOfId: true,
    hasExpiry: true,
  },
  {
    id: 6,
    name: 'PRC Card',
    category: 'primary',
    sample: [
      '../../../assets/images/sample-ids/prc-nurse.png',
      '../../../assets/images/sample-ids/prc-back.png',
    ],
    hasBackOfId: true,
    hasExpiry: true,
  },
  {
    id: 7,
    name: 'SSS ID',
    category: 'primary',
    sample: ['../../../assets/images/sample-ids/sss.png'],
    hasBackOfId: false,
    hasExpiry: false,
  },
  {
    id: 8,
    name: 'Student ID',
    category: 'primary',
    sample: [
      '../../../assets/images/sample-ids/student-id-front.jpg',
      '../../../assets/images/sample-ids/student-id-back.jpg',
    ],
    hasBackOfId: true,
    hasExpiry: true,
  },
  {
    id: 9,
    name: 'UMID Card',
    category: 'primary',
    sample: ['../../../assets/images/sample-ids/umid.png'],
    hasBackOfId: false,
    hasExpiry: false,
  },
  {
    id: 10,
    name: 'AFP ID',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/afp-id.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 11,
    name: 'Barangay Certificate',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/barangay-clearance.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 12,
    name: 'Barangay ID/ Barangay Residents Identification',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/barangay-id.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 13,
    name: 'BFP ID',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/bfp-id.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 14,
    name: 'DSWD Certification',
    category: 'secondary',
    sample: [
      '../../../assets/images/sample-ids/dswd-travel-clearance-01.png',
      '../../../assets/images/sample-ids/dswd-travel-clearance-02.png',
    ],
    hasBackOfId: true,
    hasExpiry: true,
  },
  {
    id: 15,
    name: 'GSIS ID/ eCard',
    category: 'primary',
    sample: ['../../../assets/images/sample-ids/gsis.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 16,
    name: 'OFW ID',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/ofw-ecard.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 17,
    name: 'Police Clearance',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/police-clearance.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 18,
    name: 'Postal ID',
    category: 'primary',
    sample: ['../../../assets/images/sample-ids/postal-id.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 20,
    name: 'PWD ID',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/pwd-id.png'],
    hasBackOfId: false,
    hasExpiry: true,
  },
  {
    id: 21,
    name: "Seaman's Book",
    category: 'secondary',
    sample: [
      '../../../assets/images/sample-ids/seamans-book-01.png',
      '../../../assets/images/sample-ids/seamans-book-back.png',
    ],
    hasBackOfId: true,
    hasExpiry: true,
  },
  {
    id: 22,
    name: 'Senior Citizen ID',
    category: 'secondary',
    sample: ['../../../assets/images/sample-ids/senior-id.png'],
    hasBackOfId: false,
    hasExpiry: false,
  },
];

// IDs no longer accepted
// {
//   id: 19,
//   name: 'PSA Birth Certificate',
//   category: 'secondary',
//   sample: ['../../../assets/images/sample-ids/psa-bc.png'],
//   hasBackOfId: false,
//   hasExpiry: false,
// },
// {
//   id: 23,
//   name: 'TIN ID',
//   category: 'secondary',
//   sample: ['../../../assets/images/sample-ids/tin-id.png'],
//   hasBackOfId: false,
//   hasExpiry: false,
// },
